import React from "react";
//Components

export default function ContactForm() {
	return (
		<div className="grid gap-5 border-solid border-2 border-primary rounded-primary p-5 shadow-2xl flex-1 bg-[url('./assets/cab.jpeg')] bg-cover bg-no-repeat h-full content-start text-white">
			<p>Tel : 01 23 45 67 89</p>
			<p>Email : 0xQp5@example.com</p>
		</div>
	);
}
