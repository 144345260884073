import React from "react";

export default function Description() {
	return (
		<div className="flex flex-col place-content-between">
			<h4 className="text-primary font-title text-2xl text-center">
				Bénédicte Manin
			</h4>
			<div className="flex flex-col gap-3 max-w-screen-md mx-auto">
				<p className="font-text">
					Après plusieurs années à exercer une activité
					professionnelle diversifiée mais pas assez épanouissante, un
					déséquilibre dans ma santé s’est installé. J’ai compris que
					notre équilibre de vie naît d’une attention globale sur tous
					les plans : physique, psychique, émotionnelle.
				</p>
				<p className="font-text">
					Dés mon enfance, j’ai naturellement appris à vivre avec le
					cycle des saisons et des plantes. Convaincue par les remèdes
					et les bienfaits naturels, la naturopathie s’est imposée à
					moi. Elle m’a appris à améliorer mon état de santé de façon
					naturelle, à me connaitre et écouter l’ensemble de mes
					besoins pour retrouver vitalité et harmonie.
				</p>
				<p className="font-text">
					Nous sommes acteurs de notre santé et notre bien-être, mais
					parfois le corps a besoin d’un coup de pouce et de
					direction.
				</p>
				<p className="font-text">
					Mon objectif est de vous aider à retrouver ou optimiser
					votre vitalité, à vous engager dans votre prévention santé
					et à vous reconnecter à votre propre potentiel
					d’autoguérison.
				</p>
				<p className="font-text">
					Je souhaite être à vos cotés dans votre démarche de santé et
					de mieux-être par un accompagnement personnalisé.
				</p>
				<p className="font-text">
					Je vous accueille dans un environnement chaleureux à Chonas
					l’Amballan (12 mn de Vienne), à Condrieu, ou en visio.
				</p>
			</div>
		</div>
	);
}
